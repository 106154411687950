<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <div class="fl">
          <span class="fl_1" style="font-size: 18px">{{ pageDatas.examinationInformation.examinationName }}</span>
          <span class="fl_2" style="font-size: 18px">（{{ pageDatas.examinationInformation.examUserNum }}人）</span>
        </div>
        <div class="fr">
          <span class="fr_1" @click="exitAroundCenterInfo">退出</span>
        </div>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="o_1">
            <el-select v-model="pageDatas.classroomId" clearable size="small" placeholder="请选择考场"
              v-if="pageDatas.testInformationData.length > 0" @change="pleaseselectvenue">
              <el-option v-for="item in pageDatas.testInformationData" :key="item.classroomId" :label="item.name"
                :value="item.classroomId">
              </el-option>
            </el-select>
          </div>
          <div class="o_2">
            <span class="o_2_1">监考老师：{{ userJson.fullname }}</span>
            <span class="o_2_2">考试时间：{{ pageDatas.examinationInformation.startTime }} - {{ pageDatas.examinationInformation.endTime }}</span>
            <span class="o_2_3" v-if="pageDatas.examinationInformation.examState == '10'">考试未开始</span>
            <span class="o_2_3" v-if="pageDatas.examinationInformation.examState == '20'"> 考试剩余时间：{{ pageDatas.examTimeRemaining }}</span>
          </div>
          <div class="o_3">
            <span class="o_3_1" @click="$router.go(0)"><i class="el-icon-refresh" v-if="timer"></i>刷新倒计时 <span>{{ pageDatas.time }}</span> 秒</span>
            <span class="o_3_2" @click="regulatoryRequirements()"><i class="el-icon-warning"></i>监管要求</span>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-tabs v-model="pageDatas.activeName" @tab-click="handleClick">
              <el-tab-pane :label="'答卷中 ' + '(' + pageDatas.type_1_Data.total + ')' + ' 人'" name="type_1" key="type_1">
                <template v-if="pageDatas.type_1_Data.listData.length > 0">
                  <div class="shell">
                    <div class="shell_1" v-for="(item, index) in pageDatas.type_1_Data.listData" :key="index"
                      @click="operationList(item)">
                      <img class="img_1" v-if="item.authenticationPhotoUrl" :src="item.authenticationPhotoUrl" alt="" />
                      <img class="img_1" v-else src="@/assets/examination/head.png" alt="" />
                      <img class="img_2" v-if="item.isMark" src="@/assets/examination/aroundCenterInfo_1.png" alt="" />
                      <img class="img_3" :style="item.isMark && item.authenticationState == '20' ? 'right:24px' : ''"
                        v-if="item.authenticationState == '20'" src="@/assets/examination/aroundCenterInfo_2.png"
                        alt="" />
                      <div class="shell_1_1">
                        <div class="shellf">
                          <span>
                            姓名：{{ item.userName }}
                          </span>
                          <span class="ts_1">
                            {{ item.forcedWinding ? '强制收卷' : '' }}
                          </span>
                        </div>
                        <div class="shellf">
                          <span>
                            座位号：{{ item.seatNumber || '无' }}
                          </span>
                          <span>
                            <span class="ts_1" v-if="item.isLate">迟到</span>
                            <span :class="item.isShowScreenCutNum ? 'ts_1' : ''" style="margin-left: 4px;">{{
            item.screenCutNum > 0 ? '切屏' + item.screenCutNum + '次' : '' }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-pagination :total="pageDatas.type_1_Data.total" :page-size="pageDatas.type_1_Data.size"
                    :current-page="pageDatas.type_1_Data.currentPage" background
                    :page-sizes="[30, 40, 50, 100, 200, 500]" layout="total, sizes,prev, pager, next,jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
                </template>
                <template v-else>
                  <Empty />
                </template>
              </el-tab-pane>
              <el-tab-pane :label="'已交卷 ' + '(' + pageDatas.type_2_Data.total + ')' + ' 人'" name="type_2" key="type_2">
                <template v-if="pageDatas.type_2_Data.listData.length > 0">
                  <div class="shell">
                    <div class="shell_1" v-for="(item, index) in pageDatas.type_2_Data.listData" :key="index"
                      @click="operationList(item)">
                      <img class="img_1" v-if="item.authenticationPhotoUrl" :src="item.authenticationPhotoUrl" alt="" />
                      <img class="img_1" v-else src="@/assets/examination/head.png" alt="" />
                      <img class="img_2" v-if="item.isMark" src="@/assets/examination/aroundCenterInfo_1.png" alt="" />
                      <img class="img_3" :style="item.isMark && item.authenticationState == '20' ? 'right:24px' : ''"
                        v-if="item.authenticationState == '20'" src="@/assets/examination/aroundCenterInfo_2.png"
                        alt="" />
                      <div class="shell_1_1">
                        <div class="shellf">
                          <span>
                            姓名：{{ item.userName }}
                          </span>
                          <span class="ts_1">
                            {{ item.forcedWinding ? '强制收卷' : '' }}
                          </span>
                        </div>
                        <div class="shellf">
                          <span>
                            座位号：{{ item.seatNumber || '无' }}
                          </span>
                          <span>
                            <span class="ts_1" v-if="item.isLate">迟到</span>
                            <span :class="item.isShowScreenCutNum ? 'ts_1' : ''" style="margin-left: 4px;">{{
            item.screenCutNum > 0 ? '切屏' + item.screenCutNum + '次' : '' }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-pagination :total="pageDatas.type_2_Data.total" :page-size="pageDatas.type_2_Data.size"
                    :current-page="pageDatas.type_2_Data.currentPage" background
                    :page-sizes="[30, 40, 50, 100, 200, 500]" layout="total, sizes,prev, pager, next,jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>.
                </template>
                <template v-else>
                  <Empty />
                </template>
              </el-tab-pane>
              <el-tab-pane :label="'未考试 ' + '(' + pageDatas.type_3_Data.total + ')' + ' 人'" name="type_3" key="type_3">
                <template v-if="pageDatas.type_3_Data.listData.length > 0">
                  <div class="shell">
                    <div class="shell_1" v-for="(item, index) in pageDatas.type_3_Data.listData" :key="index"
                      @click="operationList(item)">
                      <img class="img_1" v-if="item.authenticationPhotoUrl" :src="item.authenticationPhotoUrl" alt="" />
                      <img class="img_1" v-else src="@/assets/examination/head.png" alt="" />
                      <img class="img_2" v-if="item.isMark" src="@/assets/examination/aroundCenterInfo_1.png" alt="" />
                      <img class="img_3" :style="item.isMark && item.authenticationState == '20' ? 'right:24px' : ''"
                        v-if="item.authenticationState == '20'" src="@/assets/examination/aroundCenterInfo_2.png"
                        alt="" />
                      <div class="shell_1_1">
                        <div class="shellf">
                          <span>
                            姓名：{{ item.userName }}
                          </span>
                          <span class="ts_1">
                            {{ item.forcedWinding ? '强制收卷' : '' }}
                          </span>
                        </div>
                        <div class="shellf">
                          <span>
                            座位号：{{ item.seatNumber || '无' }}
                          </span>
                          <span>
                            <span class="ts_1" v-if="item.isLate">迟到</span>
                            <span :class="item.isShowScreenCutNum ? 'ts_1' : ''" style="margin-left: 4px;">{{
            item.screenCutNum > 0 ? '切屏' + item.screenCutNum + '次' : '' }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-pagination :total="pageDatas.type_3_Data.total" :page-size="pageDatas.type_3_Data.size"
                    :current-page="pageDatas.type_3_Data.currentPage" background
                    :page-sizes="[30, 40, 50, 100, 200, 500]" layout="total, sizes,prev, pager, next,jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
                </template>
                <template v-else>
                  <Empty />
                </template>
              </el-tab-pane>
              <el-tab-pane :label="'异常考生 ' + '(' + pageDatas.type_4_Data.total + ')' + ' 人'" name="type_4" key="type_4">
                <template v-if="pageDatas.type_4_Data.listData.length > 0">
                  <div class="shell">
                    <div class="shell_1" v-for="(item, index) in pageDatas.type_4_Data.listData" :key="index"
                      @click="operationList(item)">
                      <img class="img_1" v-if="item.authenticationPhotoUrl" :src="item.authenticationPhotoUrl" alt="" />
                      <img class="img_1" v-else src="@/assets/examination/head.png" alt="" />
                      <img class="img_2" v-if="item.isMark" src="@/assets/examination/aroundCenterInfo_1.png" alt="" />
                      <img class="img_3" :style="item.isMark && item.authenticationState == '20' ? 'right:24px' : ''"
                        v-if="item.authenticationState == '20'" src="@/assets/examination/aroundCenterInfo_2.png"
                        alt="" />
                      <div class="shell_1_1">
                        <div class="shellf">
                          <span>
                            姓名：{{ item.userName }}
                          </span>
                          <span class="ts_1">
                            {{ item.forcedWinding ? '强制收卷' : '' }}
                          </span>
                        </div>
                        <div class="shellf">
                          <span>
                            座位号：{{ item.seatNumber || '无' }}
                          </span>
                          <span>
                            <span class="ts_1" v-if="item.isLate">迟到</span>
                            <span :class="item.isShowScreenCutNum ? 'ts_1' : ''" style="margin-left: 4px;">{{
            item.screenCutNum > 0 ? '切屏' + item.screenCutNum + '次' : '' }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-pagination :total="pageDatas.type_3_Data.total" :page-size="pageDatas.type_3_Data.size"
                    :current-page="pageDatas.type_3_Data.currentPage" background
                    :page-sizes="[30, 40, 50, 100, 200, 500]" layout="total, sizes,prev, pager, next,jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
                </template>
                <template v-else>
                  <Empty />
                </template>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- 监管要求弹框 -->
    <el-dialog title="监管要求" :visible.sync="pageDatas.proctorsAskedloading" top="10%" width="500px" center
      :before-close="regulatoryRequirementsShutDown">
      <div class="proctorsAskedloading">
        <template v-if="pageDatas.detailsOfTestData.checkBeforeExam">
          <p>考试前身份核验</p>
          <p>验证方式：公安身份验证</p>
          <p>学员认证 <span class="ts_3">{{ pageDatas.detailsOfTestData.checkFailNumber }}</span> 次，认证未通过不允许进入考试可申请人工审核</p>
        </template>
        <template v-else>
          <p class="ts">考试前身份核验：未开启</p>
        </template>
        <p v-if="pageDatas.detailsOfTestData.lockAfterExam">考试后锁定屏幕,切屏　<span class="ts_3">
            {{ pageDatas.detailsOfTestData.cutScreenSubmit }}</span>　次自动交卷</p>
        <p v-else class="ts">考试后锁定屏幕：未开启</p>
        <p v-if="!pageDatas.detailsOfTestData.f12Allow">答题过程中禁止使用选中、复制、粘贴、F5刷新、F12开发者模式</p>
        <p v-else class="ts">答题过程中是否允许使用选中、复制、粘贴、F5刷新、F12开发者模式：未开启</p>
      </div>
    </el-dialog>
    <!-- 操作学员弹框 -->
    <el-dialog title="学员详情" :visible.sync="pageDatas.studentDetailsDataLoading" top="4%" width="600px" center
      :before-close="studentDetailsDataShutDown">
      <div class="studentDetailsData">
        <div class="studentDetailsData_t">
          <div class="shell_l">
            <p class="p_1">学员信息</p>
            <p class="P_2">准考证号：{{ pageDatas.studentDetailsData.userCandidateNo || '无' }}</p>
            <p class="P_2">考生姓名：{{ pageDatas.studentDetailsData.userName }}</p>
            <p class="P_2">身份证号：{{ pageDatas.studentDetailsData.idcard }}</p>
            <p class="P_2">职业名称：{{ pageDatas.studentDetailsData.occupationName }}</p>
            <p class="P_2">验证状态：{{ $setDictionary("EXAMINATION_AUTHENTICATION_STATE",
            pageDatas.studentDetailsData.authenticationState) }}</p>
            <p :class="['P_2', pageDatas.studentDetailsData.isShowScreenCutNum ? 'ts_1' : '']"
              v-if="pageDatas.studentDetailsData.screenCutNum > 0">切屏次数：{{
            pageDatas.studentDetailsData.screenCutNum }}
              <el-popover placement="right" width="400" trigger="click">
                <el-table :data="pageDatas.seeCutScreenLogList">
                  <el-table-column property="createTime" label="切屏时间"></el-table-column>
                  <el-table-column property="cutscreenContent" label="切屏原因"></el-table-column>
                </el-table>
                <span style="color:#409eff; cursor: pointer;" slot="reference" @click="seeCutScreenLog">查看切屏日志</span>
              </el-popover>
            </p>
            <p :class="['P_2', pageDatas.studentDetailsData.forcedWinding ? 'ts_1' : '']">强制收卷：{{
            pageDatas.studentDetailsData.forcedWinding ? '是' : '否' }}</p>
          </div>
          <div class="shell_r">
            <el-image class="img_1"
              :src="pageDatas.studentDetailsData.authenticationPhotoUrl || require('@/assets/examination/head.png')"></el-image>
          </div>
        </div>
        <div class="studentDetailsData_b">
          <p class="p_1">拍摄照片</p>
          <div class="shell">
            <template v-if="pageDatas.studentDetailsData.list && pageDatas.studentDetailsData.list.length > 0">
              <img :class="['img_1', pageDatas.studentsPhotoIndex === index ? 'ts_4' : '']" :src="item.fileUrl"
                v-for="(item, index) in pageDatas.studentDetailsData.list" :key="index" @click="selectPhotos(index)" />
            </template>
            <template v-else>
              <div class="ts_1">暂无学员认证的照片</div>
            </template>
          </div>
        </div>
        <div class="studentDetailsData_c">
          <p class="p_1">标记内容</p>
          <el-input type="textarea" placeholder="请输入标记内容" v-model="pageDatas.studentDetailsData.remark" maxlength="250"
            show-word-limit></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" plain @click="setTag">标记</el-button>
        <el-button size="small" type="primary" plain @click="increaseNumberOf">增加考试机会</el-button>
        <el-button size="small" type="danger" @click="humanReview(false)"
          v-if="(pageDatas.studentDetailsData.list && pageDatas.studentDetailsData.list.length > 0) && pageDatas.studentDetailsData.authenticationState == '20'">不通过</el-button>
        <el-button size="small" type="primary" @click="humanReview(true)"
          v-if="(pageDatas.studentDetailsData.list && pageDatas.studentDetailsData.list.length > 0) && pageDatas.studentDetailsData.authenticationState == '20'">通过</el-button>
        <el-button size="small" type="danger" @click="setWinding" :disabled="pageDatas.activeName != 'type_1'">
          强制收卷</el-button>
        <el-button size="small" type="primary" plain @click="continueAnswering" v-if="pageDatas.activeName == 'type_2'">
          继续作答</el-button>
      </span>
    </el-dialog>
    <!-- 强制收卷 -->
    <el-dialog title="强制收卷理由" :visible.sync="pageDatas.compulsoryWindingLoading" top="4%" width="500px" center
      :before-close="windingClose">
      <div class="compulsoryWinding">
        <el-input type="textarea" placeholder="请输入强制收卷理由" v-model="pageDatas.compulsoryWindingData.forcedReason"
          maxlength="250" show-word-limit></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" plain @click="windingClose">取消</el-button>
        <el-button size="small" type="primary" @click="windingOk">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "examination_onlineTest_aroundCenterInfo",
  components: {
    Empty,
    // PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        examinationId: "", // 考场id
      },
      // 页面数据
      pageDatas: {
        // 考试信息
        examinationInformation:{

        },
        // 考场id
        classroomId: "",
        // 监管要求弹框
        proctorsAskedloading: false,
        // 监管要求弹框 - 详情
        detailsOfTestData: {},
        // 考场 - 下拉数据
        testInformationData: [],
        // 考试剩余时间
        examTimeRemaining: 0,
        // 考试剩余时间 - 创造定时器
        examTimeRemainingTimer: null,
        // 选项卡默认选中
        activeName: 'type_1',
        // 答卷中列表数据
        type_1_Data: {
          total: 0, // 总条数
          size: 30, // 每页条数
          currentPage: 1, // 当前页数
          listData: [], // 列表数据
        },
        // 已交卷列表数据
        type_2_Data: {
          total: 0, // 总条数
          size: 30, // 每页条数
          currentPage: 1, // 当前页数
          listData: [], // 列表数据
        },
        // 未考试列表数据
        type_3_Data: {
          total: 0, // 总条数
          size: 30, // 每页条数
          currentPage: 1, // 当前页数
          listData: [], // 列表数据
        },
        // 异常考生列表数据
        type_4_Data: {
          total: 0, // 总条数
          size: 30, // 每页条数
          currentPage: 1, // 当前页数
          listData: [], // 列表数据
        },
        // 学员详情 - 弹框
        studentDetailsDataLoading: false,
        // 学员详情 - 数据
        studentDetailsData: {},
        // 学员认证照片的下标
        studentsPhotoIndex: "",
        // 强制收卷 - 弹框
        compulsoryWindingLoading: false,
        // 强制收卷 - 数据
        compulsoryWindingData: {
          forcedReason: "", // 强制收卷原因
        },
        // 页面每30s刷新一次
        timer: null,
        time: 30,
        // 学员切屏日志数据
        seeCutScreenLogList: [],
      },
    };
  },
  beforeDestroy() {
    this.clearTimerAll();
    this.timerStop()
  },
  created() {
    this.lastPageData.examinationId = this.$route.query?.examinationId ?? "";
    this.lastPageData.examinationName = this.$route.query?.examinationName ?? "";
    this.lastPageData.examUserNum = this.$route.query?.examUserNum ?? "";
    this.lastPageData.startTime = this.$route.query?.startTime ?? "";
    this.lastPageData.examState = this.$route.query?.examState ?? "";
    this.lastPageData.endTime = this.$route.query?.endTime ?? "";
    this.getTestInformation();
    this.getDetailsOfTest();
    this.listInit();
    this.getTestCountdown();
    this.timerInit();
  },
  watch: {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  mounted() { },
  methods: {
    // 页面每30s刷新一次
    timerInit() {
      let _this = this;
      _this.timer = setInterval(() => {
        _this.pageDatas.time--;
        if (_this.pageDatas.time < 1) {
          _this.pageDatas.time = 30;
          _this.listInit();
          _this.getTestCountdown();
        }
      }, 1000)
    },
    // 页面每30s刷新一次 - 停止定时器
    timerStop() {
      clearTimeout(this.timer)
      this.timer = null;
    },
    // 获取 - 四个列表数据
    listInit() {
      this.getListData('/biz/examination/invigilation/getExamUserAnswer', this.pageDatas.type_1_Data.currentPage, this.pageDatas.type_1_Data.size);
      this.getListData('/biz/examination/invigilation/getExamUserSubmittedPaper', this.pageDatas.type_2_Data.currentPage, this.pageDatas.type_2_Data.size);
      this.getListData('/biz/examination/invigilation/getExamUserUnexamined', this.pageDatas.type_3_Data.currentPage, this.pageDatas.type_3_Data.size);
      this.getListData('/biz/examination/invigilation/getExamUserAbnormal', this.pageDatas.type_4_Data.currentPage, this.pageDatas.type_4_Data.size);
    },
    // 获取 - 所有考场
    getTestInformation() {
      this.$post("/biz/examination/invigilation/getClassroom", { examinationId: this.lastPageData.examinationId }, 3000, true, 6)
        .then((res) => {
          this.pageDatas.testInformationData = res?.data ?? [];
        })
        .catch((err) => {
          return;
        });
    },
    // 选择考场
    pleaseselectvenue() {
      this.pageDatas.type_1_Data = this.$options.data().pageDatas.type_1_Data;
      this.pageDatas.type_2_Data = this.$options.data().pageDatas.type_2_Data;
      this.pageDatas.type_3_Data = this.$options.data().pageDatas.type_3_Data;
      this.pageDatas.type_4_Data = this.$options.data().pageDatas.type_4_Data;
      this.listInit();
    },
    // 获取 - 监管要求弹框数据
    getDetailsOfTest() {
      this.$post("/biz/examination/config/getInfo", { examinationId: this.lastPageData.examinationId }, 3000, true, 6)
        .then((res) => {
          this.pageDatas.detailsOfTestData = res.data;
        })
        .catch((err) => {
          return;
        });
    },
    // 获取 - 考试倒计时
    getTestCountdown() {
      this.$post("/biz/examination/invigilation/countdown", { examinationId: this.lastPageData.examinationId }, 3000, true, 6)
        .then((res) => {
          console.log(res)
          this.pageDatas.examinationInformation = res.data;

          if (res.data?.durationSecond && res.data.durationSecond > 0) {
            let t = Number(res.data.durationSecond)
            this.pageDatas.examTimeRemainingTimer = setInterval(() => {
              if (t <= 1) {
                this.clearTimerAll();
                this.timerStop();
                this.$message({
                  message: '考试已结束',
                  type: 'warning'
                });
                this.$router.back();
                return
              }
              t--;
              this.pageDatas.examTimeRemaining = this.formatSeconds(t, true)
            }, 1000);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 时间转换 - 秒 - 转 => 分：秒
    formatSeconds(value, isHaveHour) {
      // 是否需要小时 isHaveHour
      let secondTime = parseInt(value);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60 && isHaveHour) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取余的分，获取分钟除以60取余的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "";
      if (secondTime < 10) {
        result = "0" + parseInt(secondTime);
      } else {
        result = "" + parseInt(secondTime);
      }

      if (minuteTime > 0 && minuteTime < 10) {
        result = "0" + parseInt(minuteTime) + ":" + result;
      } else if (minuteTime >= 10) {
        result = "" + parseInt(minuteTime) + ":" + result;
      } else {
        result = '00:' + result
      }
      if (isHaveHour) {
        if (hourTime > 0 && hourTime < 10) {
          result = "0" + parseInt(hourTime) + ":" + result;
        } else if (hourTime >= 10) {
          result = "" + parseInt(hourTime) + ":" + result;
        } else {
          result = '00:' + result
        }
      }
      // console.log('result',result);
      return result;
    },
    // 考试倒计时 - 清楚定时器
    clearTimerAll() {
      clearInterval(this.pageDatas.examTimeRemainingTimer); // 清除定时器
      this.pageDatas.examTimeRemainingTimer = null; // 清空定时器事件
      this.pageDatas.examTimeRemaining = 0; // 考试事件归0
    },
    // 监考信息 - 打开弹框
    regulatoryRequirements() {
      this.pageDatas.proctorsAskedloading = true;
    },
    // 监考信息 - 关闭弹框
    regulatoryRequirementsShutDown() {
      this.pageDatas.proctorsAskedloading = false;
    },
    // 获取 - 列表数据
    getListData(url, pageNum, pageSize) {
      let params = {
        examinationId: this.lastPageData.examinationId,
        classroomId: this.pageDatas.classroomId,
        pageNum: pageNum,
        pageSize: pageSize
      };
      this.$post(url, params, 3000, true, 6)
        .then((res) => {
          if (url == '/biz/examination/invigilation/getExamUserAnswer') {
            this.pageDatas.type_1_Data.listData = res.data.list;
            this.pageDatas.type_1_Data.total = res.data.total;
          }
          if (url == '/biz/examination/invigilation/getExamUserSubmittedPaper') {
            this.pageDatas.type_2_Data.listData = res.data.list;
            this.pageDatas.type_2_Data.total = res.data.total;
          }
          if (url == '/biz/examination/invigilation/getExamUserUnexamined') {
            this.pageDatas.type_3_Data.listData = res.data.list;
            this.pageDatas.type_3_Data.total = res.data.total;
          }
          if (url == '/biz/examination/invigilation/getExamUserAbnormal') {
            this.pageDatas.type_4_Data.listData = res.data.list;
            this.pageDatas.type_4_Data.total = res.data.total;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 分页 - 每页多少条
    handleSizeChange(val) {
      // 答卷中
      if (this.pageDatas.activeName == 'type_1') {
        this.pageDatas.type_1_Data.size = val;
        this.pageDatas.type_1_Data.currentPage = 1;
        this.getListData('/biz/examination/invigilation/getExamUserAnswer', this.pageDatas.type_1_Data.currentPage, this.pageDatas.type_1_Data.size);
      }
      // 已交卷
      if (this.pageDatas.activeName == 'type_2') {
        this.pageDatas.type_2_Data.size = val;
        this.pageDatas.type_2_Data.currentPage = 1;
        this.getListData('/biz/examination/invigilation/getExamUserSubmittedPaper', this.pageDatas.type_2_Data.currentPage, this.pageDatas.type_2_Data.size);
      }
      // 未考试
      if (this.pageDatas.activeName == 'type_3') {
        this.pageDatas.type_3_Data.size = val;
        this.pageDatas.type_3_Data.currentPage = 1;
        this.getListData('/biz/examination/invigilation/getExamUserUnexamined', this.pageDatas.type_3_Data.currentPage, this.pageDatas.type_3_Data.size);
      }
      // 异常考生
      if (this.pageDatas.activeName == 'type_4') {
        this.pageDatas.type_4_Data.size = val;
        this.pageDatas.type_4_Data.currentPage = 1;
        this.getListData('/biz/examination/invigilation/getExamUserAbnormal', this.pageDatas.type_4_Data.currentPage, this.pageDatas.type_4_Data.size);
      }
    },
    // 分页 - 当前页数
    handleCurrentChange(val) {
      // 答卷中
      if (this.pageDatas.activeName == 'type_1') {
        this.pageDatas.type_1_Data.currentPage = val;
        this.getListData('/biz/examination/invigilation/getExamUserAnswer', this.pageDatas.type_1_Data.currentPage, this.pageDatas.type_1_Data.size);
      }
      // 已交卷
      if (this.pageDatas.activeName == 'type_2') {
        this.pageDatas.type_2_Data.currentPage = val;
        this.getListData('/biz/examination/invigilation/getExamUserSubmittedPaper', this.pageDatas.type_2_Data.currentPage, this.pageDatas.type_2_Data.size);
      }
      // 未考试
      if (this.pageDatas.activeName == 'type_3') {
        this.pageDatas.type_3_Data.currentPage = val;
        this.getListData('/biz/examination/invigilation/getExamUserUnexamined', this.pageDatas.type_3_Data.currentPage, this.pageDatas.type_3_Data.size);
      }
      // 异常考生
      if (this.pageDatas.activeName == 'type_4') {
        this.pageDatas.type_4_Data.currentPage = val;
        this.getListData('/biz/examination/invigilation/getExamUserAbnormal', this.pageDatas.type_4_Data.currentPage, this.pageDatas.type_4_Data.size);
      }
    },
    // 学员详情 - 打开弹框
    operationList(item) {
      this.pageDatas.studentDetailsData = item;
      this.pageDatas.studentDetailsData.list = item?.list ?? [];
      this.$set(this.pageDatas.studentDetailsData, 'remark', item?.markRemark ?? "");
      this.pageDatas.studentDetailsDataLoading = true;
    },
    // 学员详情 - 关闭弹框
    studentDetailsDataShutDown() {
      this.pageDatas.studentsPhotoIndex = "";
      this.pageDatas.studentDetailsDataLoading = false;
    },
    // 学员详情 - 弹框 - 查看切屏日志
    seeCutScreenLog() {
      console.log(this.pageDatas.studentDetailsData)
      this.$post("/biz/examination/performance/cutscreen/logList", {
        examinationPerformanceId: this.pageDatas.studentDetailsData.examinationPerformanceId,
      }, 3000, true, 6)
        .then((res) => {
          this.pageDatas.seeCutScreenLogList = res.data || [];
          this.pageDatas.studentDetailsData.screenCutNum = res.data.length || 0;
        })
        .catch((err) => {
          console.log(err)
          return;
        });
    },
    // 学员详情 - 弹框 - 标记
    setTag() {
      // 判断是否填写了备注信息
      if (this.pageDatas.studentDetailsData.remark) {
        this.$post("/biz/examination/invigilation/mark", {
          examinationId: this.lastPageData.examinationId,
          userId: this.pageDatas.studentDetailsData.userId,
          remark: this.pageDatas.studentDetailsData.remark
        }, 3000, true, 6)
          .then((res) => {
            this.listInit();
            this.studentDetailsDataShutDown();
            this.$message.success('操作成功！');
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$message({
          message: '请填写标记内容',
          type: 'warning'
        });
      }
    },
    // 学员详情 - 弹框 - 增加考试机会
    increaseNumberOf() {
      this.$post("/biz/examination/invigilation/additionalNumber", {
        examinationId: this.lastPageData.examinationId,
        userId: this.pageDatas.studentDetailsData.userId
      }, 3000, true, 6)
        .then((res) => {
          this.$message.success('操作成功！');
          this.listInit();
          this.studentDetailsDataShutDown();
        })
        .catch((err) => {
          return;
        });
    },
    // 学员详情 - 弹框 - 人工审核 - 选择对应的人脸照片
    selectPhotos(i) {
      this.pageDatas.studentsPhotoIndex = i;
    },
    // 学员详情 - 弹框 - 人工审核 - 确定
    humanReview(type) {
      // 通过人工审核
      if (type) {
        // 判断是否选择了要认证的照片
        if (typeof this.pageDatas.studentsPhotoIndex != 'string') {
          this.$post("/biz/examination/invigilation/review", {
            examinationId: this.lastPageData.examinationId,
            userId: this.pageDatas.studentDetailsData.userId,
            isPass: type,
            authenticationPhoto: this.pageDatas.studentDetailsData.list[this.pageDatas.studentsPhotoIndex].fileKey
          }, 3000, true, 6)
            .then((res) => {
              this.$message.success('操作成功！')
              this.listInit();
              this.studentDetailsDataShutDown();
            })
            .catch((err) => {
              return;
            });
        } else {
          this.$message({
            message: '请选择一张照片为认证照片',
            type: 'warning'
          });
        }
      } else {
        this.$post("/biz/examination/invigilation/review", {
          examinationId: this.lastPageData.examinationId,
          userId: this.pageDatas.studentDetailsData.userId,
          isPass: type,
        }, 3000, true, 6)
          .then((res) => {
            this.$message.success('操作成功！')
            this.listInit();
            this.studentDetailsDataShutDown();
          })
          .catch((err) => {
            return;
          });
      }
    },
    // 学员详情 - 弹框 - 强制收卷
    setWinding() {
      this.pageDatas.compulsoryWindingLoading = true;
    },
    // 学员详情 - 弹框 - 强制收卷 - 确定
    windingOk() {
      if (this.pageDatas.compulsoryWindingData.forcedReason) {
        this.$post("/biz/examination/invigilation/forcedWinding", {
          examinationId: this.lastPageData.examinationId,
          userId: this.pageDatas.studentDetailsData.userId,
          forcedReason: this.pageDatas.compulsoryWindingData.forcedReason
        }, 3000, true, 6)
          .then((res) => {
            this.listInit();
            this.windingClose();
            this.studentDetailsDataShutDown();
            this.$message.success('操作成功！');
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$message({
          message: '请填写强制收卷理由',
          type: 'warning'
        });
      }
    },
    // 学员详情 - 弹框 - 强制收卷 - 关闭
    windingClose() {
      this.pageDatas.compulsoryWindingLoading = false;
      this.pageDatas.compulsoryWindingData = this.$options.data().pageDatas.compulsoryWindingData;
    },
    // 继续作答
    continueAnswering() {
      this.$post("/biz/examination/invigilation/continueAnswer", {
        examinationId: this.lastPageData.examinationId,
        userId: this.pageDatas.studentDetailsData.userId
      }, 3000, true, 6)
        .then((res) => {
          this.listInit();
          this.studentDetailsDataShutDown();
          this.$message.success('操作成功！');
        })
        .catch((err) => {
          return;
        });
    },
    // 退出
    exitAroundCenterInfo() {
      this.$router.back();
    }
  },
};
</script>
<style lang="less" scoped>
.ts_1 {
  color: #FF4A2E !important;
}

.ts_2 {
  color: #fff !important;
}

.ts_3 {
  color: #2878FF !important;
}

.ts_4 {
  border: 1px solid #2878FF;
}

.framePage {
  padding: 0;

  .framePage-title {
    height: 60px;
    background: #2878FF;
    color: #fff;
    align-items: center;
    margin-bottom: 0;

    .fl {
      .fl_1 {
        margin-right: 10px;
        font-size: 20px;
      }
    }

    .fr {
      .fr_1 {
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }
  }

  .framePage-body {
    padding: 0 0.675rem;

    .operationControl {
      padding: 15px 0.7rem;
      border-bottom: 0;

      >div {
        font-size: 14px;

      }

      .o_2 {
        .o_2_2 {
          margin: 0 10px;
        }

        .o_2_3 {
          color: #FF4A2E;
        }
      }

      .o_3 {
        color: #666666;
        cursor: pointer;
        .o_3_1 {
          span {
            width: 20px !important;
            min-width: 20px;
            padding: 0;
            text-align: center;
            color: #ff892e;
          }
          i {
            color: #2878FF;
            font-size: 16px;
            margin-right: 4px;
          }
          &:hover {
            color: #2878FF;
          }
        }
        .o_3_2 {
          margin-left: 20px;
          i {
            color: #ff892e;
            font-size: 16px;
            margin-top: 2.5px;
          }
          &:hover {
            color: #ff892e;
          }
        }
      }
    }

    .framePage-scroll {
      padding: 0;

      .ovy-a {
        overflow: inherit;
      }
    }

    .el-tabs {
      /deep/ .el-tabs__item {
        height: 34px !important;
        line-height: 34px !important;
        font-size: 12px !important;
      }

      /deep/ .el-tabs__content {
        min-height: 500px;
      }

      .shell {
        height: calc(100vh - 240px);
        overflow-y: auto;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        .shell_1 {
          width: 158px;
          height: 240px;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 1px solid #F1F1F1;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          cursor: pointer;

          .img_1 {
            width: 100%;
            height: 100%;
            position: absolute;
          }

          .img_2 {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 4px;
            right: 4px;
          }

          .img_3 {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 4px;
            right: 4px;
          }

          .shell_1_1 {
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, .7);

            .shellf {
              color: #fff;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              padding: 0 4px;

              span {
                line-height: 20px;
              }
            }
          }
        }
      }

      .el-pagination {
        text-align: right;
      }
    }
  }
}

.proctorsAskedloading {
  line-height: 26px;

  .ts {
    color: red;
  }
}

.studentDetailsData {
  .studentDetailsData_t {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

    .shell_l {
      line-height: 24px;
      margin-right: 20px;

      .p_1 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .shell_r {
      .img_1 {
        width: 114px;
        height: 160px;
        border-radius: 4px;
      }
    }
  }

  .studentDetailsData_b {
    margin-top: 20px;

    .p_1 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .shell {
      white-space: nowrap;
      overflow-x: auto;

      .img_1 {
        width: 94px;
        height: 132px;
        border-radius: 4px;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;

        &.ts_4 {
          border-width: 2px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  .studentDetailsData_c {
    margin-top: 20px;

    .p_1 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .el-textarea {
      height: 80px;

      /deep/ .el-textarea__inner {
        height: 80px !important;
      }
    }
  }
}
</style>